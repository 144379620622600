import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import WhyChooseUs from "../components/WhyChooseUs";
import Image1 from "../assets/images/hero/man-worker-firld-by-solar-panels_1303-15573.jpg";
import Image2 from "../assets/images/hero/6.png";
import Contact from "../components/Contact";
export default function GgLp1() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const PhoneNumber = {
    PhoneNo: "(866) 780-2318",
  };
  const HeroSectionContant = {
    Heading: `Slash Your Energy Bills by $1000’s`,
    subHeading: `Connect with Solar Experts <br/> Today <br/> Free Consultation`,
    Para: ``,
    Image1: Image1,
    Image2: Image2,
  };
  const ContactContent = {
    Heading: `Solar Panels are The Best Way to Save Money on Your Energy Bills`,
    Para: `Take The Advantage Of Saving Money On Your Energy Bills
Find Top Solar Providers In Your Area With First Rate Solar Systems`,
  };
  return (
    <div>
       <ExternalScriptsLoader />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroSectionContant={HeroSectionContant} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber}/>
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
