import React from "react";
import Image1 from "../assets/images/photos/robot-with-solar-panel_1048-2917-removebg-preview.png";
import Image2 from "../assets/images/photos/power-supply-sun-machinery-sunny-holding_1134-1007.jpg";

export default function Services({ PhoneNumber }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <>
    <div className="s">
    <div className="solar-panel-section">
        {/* Right Content */}
        <div className="solar-panel-content">
          <h2 className="content-title">
            Top-Notch Solar Panels for Your Home or Business
          </h2>

          <ul className="content-list">
            <li>
              <span>✔</span> Making the switch to solar can be daunting, but
              it’s a wise, long-term investment. Not only will you contribute to
              environmental sustainability, but you’ll also enjoy significant
              savings on your monthly electricity bill.
            </li>
            <li>
              <span>✔</span> First Rate Solar Systems simplifies the transition
              to solar. We connect you with local solar experts offering
              top-of-the-line solar panels at unbeatable prices, ensuring you
              get the best system for your needs.
            </li>
            <li>
              <span>✔</span>
              Contact us today to discover how we can help you go green and
              save!
            </li>
          </ul>
        </div>
        {/* Left Images */}
        <div className="solar-panel-images">
          <div className="image-card">
            <img src={Image2} alt="Solar Panel 1" />
          </div>
          <img className="imh" src={Image1} alt="" />
        </div>
      </div>
      <div className="btn-group">
        <a href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
      </div>
    </div>
      
    </>
  );
}
