import React from "react";
import Image from "../assets/images/hero/download.png";
export default function HowItWorks({ PhoneNumber }) {
  return (
    <div className="HowWorks">
      <div className="rel">
        <div className="works">
        <div className="works-right">
            <img src={Image} alt="" />
          </div>
          <div className="works-left">
            <h2>Transform Your Energy Savings</h2>
            <p>
              First Rate Solar Systems is your gateway to significant savings on
              your electric bill while benefiting the environment. Solar panels
              are an excellent way to decrease reliance on fossil fuels and
              boost your property's value.
            </p>
            <p>
              Solar energy is now more accessible and affordable than ever,
              thanks to technological advancements and government incentives.
            </p>
          </div>
          
        </div>
        {/* <div className="abs"> </div> */}
      </div>
    </div>
  );
}
