import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import WhyChooseUs from "../components/WhyChooseUs";
import Image1 from "../assets/images/hero/engineer-electric-woman-checking-maintenance-solar-cells_1150-4279.jpg";
import Image2 from "../assets/images/hero/download.png";
import Contact from "../components/Contact";

export default function DlLp3() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const PhoneNumber = {
    PhoneNo: "(844) 447-0790",
  };
  const HeroSectionContant = {
    Heading: `Save Over $1000 Annually On Your Electricity Bill`,
    subHeading: `We Help You Find Top-Rated Solar Installers in Your Area`,
    Para: ``,
    Image1: Image1,
    Image2: Image2,
  };
  const ContactContent = {
    Heading: `Solar Panels are The Best Way to Save Money on Your Energy Bills`,
    Para: `Take The Advantage Of Saving Money On Your Energy Bills
Find Top Solar Providers In Your Area With First Rate Solar Systems`,
  };
  return (
    <div>
       <ExternalScriptsLoader />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroSectionContant={HeroSectionContant} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber}/>
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
