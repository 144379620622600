import React from "react";
import Image1 from "../assets/images/Downloads/Power Up Your Future.png";
import Image2 from "../assets/images/Downloads/Slash Your Energy Costs.png";
import Image3 from "../assets/images/Downloads/Boost Your Property Value.png";
import Image4 from "../assets/images/Downloads/Reap the Tax Benefits.png"
export default function WhyChooseUs({ PhoneNumber }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  const cards = [
    {
      icon: Image1,
      title: "Power Up Your Future",
      description:
        "Invest in your future with solar energy. It's sustainable, renewable, and, most importantly, free!",
    },
    {
      icon: Image2,
      title: "Slash Your Energy Costs",
      description:
        "Harness the sun’s power to save thousands each year. On average, a residential solar system can cut your electricity bill by up to $1500 annually.",
    },
    {
      icon: Image3,
      title: "Boost Your Property Value",
      description:
        "A solar panel system can increase your home or business value by as much as 4.1 percent.",
    },
    {
      icon: Image4,
      title: "Reap the Tax Benefits",
      description:
        "By going solar through First Rate Solar Systems, you could qualify for a federal tax credit of up to 30% of your system's cost.",
    },
  ];

  return (
    <div className="why-choose-us">
      <h2 className="section-title">Why Choose Us?</h2>
      <div className="cards-container">
        {cards.map((card, index) => (
          <>
            <div className="line"></div>
            <div className="card" key={index}>
              <div className="icon"><img src={card.icon} alt="" /></div>
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
            </div>
          </>
        ))}
      </div>
      <div className="btn-group">
        <a href={`tel:${telPhoneNumber}`}> CALL NOW: {PhoneNumber.PhoneNo}</a>
      </div>
    </div>
  );
}
