import React, { useState } from "react";
import Navbar from "./Navbar";
export default function HeroSection({ PhoneNumber ,  HeroSectionContant }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;


  return (
    <div className="HeroSection">
      <div className="rel">
        <Navbar PhoneNumber={PhoneNumber} />
        <div className="hero">
          <div className="hero-left">
            <h1>{HeroSectionContant.Heading}</h1>
            <h3 dangerouslySetInnerHTML={{__html: HeroSectionContant.subHeading}}/>
            <p>{HeroSectionContant.Para}</p>
            <div className="btn-group">
              <a href={`tel:${telPhoneNumber}`}>
                {" "}
                CALL NOW: {PhoneNumber.PhoneNo}
              </a>
            </div>
          </div>
          <div className="hero-right">
            <img className="img1" src={HeroSectionContant.Image1} alt="" />
            
          </div>
        </div>
       
      </div>
    </div>
  );
}
