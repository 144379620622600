import React from "react";
import Image from "../assets/images/photos/662a267150dec9e47d9b4dce_perso-garantie-min.webp";
export default function Contact({ PhoneNumber , ContactContent }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="Contact">
      <div className="outer-cart">
        <div className="cnt-card">
          <h3>{ContactContent.Heading}</h3>
          <p>{ContactContent.Para}</p>
          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
              {" "}
              CALL NOW: {PhoneNumber.PhoneNo}
            </a>
          </div>
        </div>
      </div>

   
    </div>
  );
}
