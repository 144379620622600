import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Image1 from "./assets/images/hero/lp.jpg";
import Image2 from "./assets/images/hero/3.png";
export default function landingPage() {
  const PhoneNumber = {
    PhoneNo: "(844) 447-0790",
  };
  const HeroSectionContant = {
    Heading: `Tired of High Electricity Bills?`,
    subHeading: `Save Big - Switch To Solar `,
    Para: `Connect with local solar experts through First Rate Solar Systems
              to ensure you receive the highest quality solar panels and
              installations at the most competitive prices`,
    Image1: Image1,
    Image2: Image2,
  };
  const ContactContent = {
    Heading: `Embrace solar power and save thousands!`,
    Para: `Cut Your Electricity Bill And Support A Greener Planet With Solar Panels. Connect With Local Solar Experts Today!`,
  };
  return (
    <div>
      {/* <Navbar PhoneNumber={PhoneNumber} /> */}
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroSectionContant={HeroSectionContant} PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      
      <Services PhoneNumber={PhoneNumber} />
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
